/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Form,
  Switch,
  Typography,
  Tooltip,
  Space,
  Select,
  Row,
  Col,
  Input,
  Checkbox,
  Spin,
} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  EBusiness,
  IChangeAutomatedResponseFormValues,
  IDetailWorkingTime,
  IPayloadChangeAutomatedResponse,
  IPayloadGetAutomatedResponse,
} from 'pages/setting/interfaces';
import range from 'lodash/range';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import { useAppDispatch, useAppSelector, useMyOrganizationID, useReadOnly } from 'app/hooks';
import {
  changeAutomatedResponseAction,
  loadingAutomatedResponseAction,
  changeOnOffResponseAction,
} from 'actions/automated-response';
import trim from 'lodash/trim';
import ConfirmButton from 'components/common/ConfirmButton';
import { translate } from 'libs';

const { Text } = Typography;
const { Option } = Select;

const timeInWorks = { start_time: '9 AM', end_time: '5 PM' };

const weekdays: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const defaultSettings = {
  Sunday: {
    type: EBusiness.HOURS_OPERATIONS_24H,
  },
  Monday: {
    type: EBusiness.HOURS_OPERATIONS_24H,
  },
  Tuesday: {
    type: EBusiness.HOURS_OPERATIONS_24H,
  },
  Wednesday: {
    type: EBusiness.HOURS_OPERATIONS_24H,
  },
  Thursday: {
    type: EBusiness.HOURS_OPERATIONS_24H,
  },
  Friday: {
    type: EBusiness.HOURS_OPERATIONS_24H,
  },
  Saturday: {
    type: EBusiness.HOURS_OPERATIONS_24H,
  },
};

const initWorkingTimes = weekdays.map((weekday: string) => ({
  type: EBusiness.HOURS_OPERATIONS_24H,
  label: weekday,
}));

const quickWorkingTimes = weekdays.map((weekday: string) => {
  if (['Sunday', 'Saturday'].includes(weekday)) {
    return { type: EBusiness.OFF_BUSINESS, label: weekday };
  }

  return { type: EBusiness.NORMAL_BUSINESS_HOURS, label: weekday, ...timeInWorks };
});

const optionsSetting: EBusiness[] = [
  EBusiness.NORMAL_BUSINESS_HOURS,
  EBusiness.HOURS_OPERATIONS_24H,
  EBusiness.OFF_BUSINESS,
];

const listTimesInWork: string[] = flatten(
  range(0, 24).map((hour: number) => {
    const hours = [0, 15, 30, 45].map((minute: number) => {
      let midday: string = 'AM';
      let newHour = hour;

      if (hour > 11) {
        midday = 'PM';
        if (hour > 12) {
          newHour = hour - 12;
        }
      }
      if (minute === 0) {
        return `${newHour} ${midday}`;
      }
      return `${newHour}:${minute} ${midday}`;
    });

    return hours.filter((time: string) => !!time);
  })
);

const HoursOfOperation = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [commonData, setCommonData] = React.useState<any>(null);

  const isLoading: boolean = useAppSelector((state) => state.automatedResponses.isLoading);

  const myOrgId: string = useMyOrganizationID();

  const isJustView: boolean = useReadOnly();

  const loadingAutomatedResponse = React.useCallback(() => {
    const payload: IPayloadGetAutomatedResponse = {
      organization_id: myOrgId,
      onSuccess: (data: any) => {
        const newWorkingTimes: any = [];

        if (data && data?.on_off && !data?.working_times) {
          data.working_times = defaultSettings;
        }

        if (data && data?.working_times && Object.keys(data?.working_times).length > 0) {
          Object.entries(data.working_times).forEach(([key, value]: any) => {
            const itemWorkingTime: any = {
              type: value?.type,
              label: key,
            };

            if (value?.type === EBusiness.NORMAL_BUSINESS_HOURS) {
              const startTime = value?.start_time || '09 AM';
              const endTime = value?.end_time || '05 PM';

              const getTimeString = (time: string): string => {
                const [hour, midday]: string[] = time.split(' ');

                const checkHour: string[] = hour.split(':');

                const getHour: string =
                  checkHour[0].charAt(0) === '0'
                    ? checkHour[0].substr(1, checkHour[0].length)
                    : checkHour[0];
                const getMinutes: string | null = checkHour?.[1];

                const fullHour: string =
                  getMinutes && getMinutes !== '00' ? `${getHour}:${getMinutes}` : getHour;

                const timeString: string = `${fullHour} ${midday}`;

                return timeString;
              };

              itemWorkingTime.start_time = getTimeString(startTime);
              itemWorkingTime.end_time = getTimeString(endTime);
            }

            newWorkingTimes.push(itemWorkingTime);
          });
        }

        const isCheckQuickSetting: boolean = isEqual(newWorkingTimes, quickWorkingTimes);
        form.setFieldsValue({
          on_off: !!data?.on_off,
          reporting_message: data?.reporting_message,
          messager_message: data?.messager_message,
          allow_editting_sub_org: !!data?.allow_editting_sub_org,
          working_times: newWorkingTimes,
          quick_setting: isCheckQuickSetting,
        });

        setCommonData(data);
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        form.setFieldsValue({ on_off: false });
      },
    };

    return dispatch(loadingAutomatedResponseAction(payload));
  }, [myOrgId]);

  React.useEffect(() => {
    if (!myOrgId) return;
    loadingAutomatedResponse();
  }, [myOrgId]);

  const handleChangeQuickSetting = (event: any) => {
    const { checked } = event.target;

    if (checked) {
      return form.setFieldsValue({
        working_times: quickWorkingTimes,
      });
    }

    return form.setFieldsValue({ quick_setting: false, working_times: initWorkingTimes });
  };

  const handleChangeBusiness = (value: EBusiness, index: number) => {
    const newWorkingTimes = [...form.getFieldValue('working_times')];
    const item = newWorkingTimes[index];

    if (value === EBusiness.NORMAL_BUSINESS_HOURS) {
      newWorkingTimes.splice(index, 1, { ...item, ...timeInWorks });
    } else {
      newWorkingTimes.splice(index, 1, { type: item.type, label: item.label });
    }

    form.setFieldsValue({ working_times: newWorkingTimes });
  };

  const onFinish = ({
    // allow_editting_sub_org,
    on_off,
    reporting_message,
    messager_message,
    working_times,
    ...values
  }: IChangeAutomatedResponseFormValues) => {
    const newWorkingTimes: any = {};
    if (Array.isArray(working_times)) {
      working_times.forEach((item: any) => {
        const itemWorkingTime: IDetailWorkingTime = {
          type: item?.type,
        };
        if (item?.type === EBusiness.NORMAL_BUSINESS_HOURS) {
          const getTimeString = (timeString: string): string => {
            const [time, midday]: string[] = timeString.split(' ');

            let [hour, minute]: string[] = time.split(':');
            if (parseInt(hour) < 10) {
              hour = `0${hour}`;
            }
            const newHour: string = `${hour || '09'}:${minute || '00'}`;
            return `${`${newHour}`} ${midday}`;
          };

          if (item?.start_time) {
            itemWorkingTime.start_time = getTimeString(item.start_time);
          }
          if (item?.end_time) {
            itemWorkingTime.end_time = getTimeString(item.end_time);
          }
        }

        newWorkingTimes[item.label] = itemWorkingTime;
      });
    }
    form.setFieldsValue({ reporting_message: trim(reporting_message) });
    form.setFieldsValue({ messager_message: trim(messager_message) });
    const payload: IPayloadChangeAutomatedResponse = {
      values: {
        organization_id: myOrgId,
        allow_editting_sub_org: false,
        on_off,
        working_times: newWorkingTimes,
        reporting_message: trim(reporting_message),
        messager_message: trim(messager_message),
      },
    };

    return dispatch(changeAutomatedResponseAction(payload));
  };

  const handleSwitchAuto = (checked: boolean) => {
    const payload: IPayloadChangeAutomatedResponse = {
      values: {
        organization_id: myOrgId,
        allow_editting_sub_org: !!commonData?.allow_editting_sub_org,
        on_off: checked,
        working_times: commonData?.working_times || defaultSettings,
        reporting_message: trim(commonData?.reporting_message) || '',
        messager_message: trim(commonData?.messager_message) || '',
      },
      onSuccess: () => {
        form.setFieldsValue({ working_times: initWorkingTimes });
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        form.setFieldsValue({ on_off: false });
      },
    };
    return dispatch(changeOnOffResponseAction(payload));
  };

  return (
    <div className="hours-of-operation">
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          initialValues={{
            on_off: false,
            allow_editting_sub_org: false,
            working_times: initWorkingTimes,
            reporting_message: '',
            messager_message: '',
          }}
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item className="form-auto">
            <Form.Item name="on_off" valuePropName="checked">
              <Switch
                className="switch-auto"
                size="small"
                disabled={isJustView}
                loading={isLoading}
                onChange={handleSwitchAuto}
              />
            </Form.Item>
            <div className="wrap-txt">
              <Text>{translate('settingPage.automatedResponse')}</Text>
            </div>

            <Tooltip title={translate('settingPage.automatedResponse.description')}>
              <InfoCircleFilled className="ic-info" />
            </Tooltip>
          </Form.Item>

          <Space direction="vertical" size={15} className="auto-info max-text-div">
            <Text>{translate('settingPage.automatedResponse.useThisTool')}</Text>
            <Text>{translate('settingPage.automatedResponse.setYourMessage')}</Text>
          </Space>

          <Form.Item noStyle shouldUpdate={(p, n) => p.on_off !== n.on_off}>
            {({ getFieldValue }) => {
              const isAuto: boolean = getFieldValue('on_off');

              if (!isAuto) return null;

              return (
                <React.Fragment>
                  <div className="hoo-messages">
                    <Text>{translate('settingPage.automatedResponse.hooMessages')}</Text>
                  </div>

                  <Form.Item
                    name="quick_setting"
                    label={translate('settingPage.automatedResponse.quickSetting')}
                    valuePropName="checked"
                  >
                    <Checkbox
                      className="cb-quick-setting"
                      onChange={handleChangeQuickSetting}
                      disabled={isJustView}
                    >
                      {translate('settingPage.automatedResponse.weekday')}
                    </Checkbox>
                  </Form.Item>

                  <Text className="ant-form-item-label">
                    {translate('settingPage.automatedResponse.customSetting')}
                  </Text>

                  <Form.List name="working_times">
                    {(fields) => (
                      <Space className="space-custom" align="start">
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space key={key} direction="vertical" className="space-custom-item">
                            <Form.Item noStyle shouldUpdate>
                              {({ getFieldValue: gfvl }) => {
                                const isOB: boolean =
                                  gfvl('working_times')?.[name]?.type === EBusiness.OFF_BUSINESS;
                                const is24HO: boolean =
                                  gfvl('working_times')?.[name]?.type ===
                                  EBusiness.HOURS_OPERATIONS_24H;

                                return (
                                  <React.Fragment>
                                    <Form.Item
                                      {...restField}
                                      name={[name!, 'type']}
                                      fieldKey={[fieldKey!, 'type']}
                                      label={translate(
                                        `settingPage.automatedResponse.${weekdays[name]!}`
                                      )}
                                    >
                                      <Select
                                        onChange={(value: EBusiness) =>
                                          handleChangeBusiness(value, name)
                                        }
                                        size="large"
                                        disabled={isJustView}
                                        dropdownClassName="select-type-working"
                                      >
                                        {optionsSetting.map((type: EBusiness) => (
                                          <Option key={`${name}-${type}`} value={type}>
                                            {translate(`settingPage.automatedResponse.${type}`)}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                    {!isOB && !is24HO && (
                                      <React.Fragment>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'start_time']}
                                          fieldKey={[fieldKey!, 'start_time']}
                                          className="from"
                                        >
                                          <Select size="large">
                                            {listTimesInWork.map((time: string) => (
                                              <Option value={time} key={`${name}-${time}`}>
                                                {time}
                                              </Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'end_time']}
                                          fieldKey={[fieldKey!, 'end_time']}
                                          label={translate('settingPage.automatedResponse.to')}
                                          className="to"
                                        >
                                          <Select size="large">
                                            {listTimesInWork.map((time: string) => (
                                              <Option value={time} key={`${name}-${time}`}>
                                                {time}
                                              </Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                );
                              }}
                            </Form.Item>
                          </Space>
                        ))}
                      </Space>
                    )}
                  </Form.List>

                  <div className="hoo-messages">
                    <Text>{translate('settingPage.automatedResponse.messages')}</Text>
                  </div>

                  <Row gutter={24} className="row-messages">
                    <Col span={12}>
                      <Form.Item
                        name="reporting_message"
                        label={translate('settingPage.automatedResponse.reportingMessage')}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: translate(
                              'settingPage.automatedResponse.reportingMessageRequired'
                            ),
                          },
                        ]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 6, maxRows: 6 }}
                          placeholder={translate(
                            'settingPage.automatedResponse.reportingMessagePlaceholder'
                          )}
                          className="input-message"
                          maxLength={256}
                        />
                      </Form.Item>
                      <ConfirmButton
                        className="btn-edit"
                        buttonTitle={translate('settingPage.automatedResponse.editBtn2')}
                        onClick={() => form.submit()}
                        form={form}
                        size="large"
                        isLoading={isLoading}
                      />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="messager_message"
                        label={translate('settingPage.automatedResponse.messengerMessge')}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: translate(
                              'settingPage.automatedResponse.messengerMessgeRequired'
                            ),
                          },
                        ]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 6, maxRows: 6 }}
                          placeholder={translate(
                            'settingPage.automatedResponse.messengerMessgePlaceholder'
                          )}
                          className="input-message"
                          maxLength={256}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* End */}
                </React.Fragment>
              );
            }}
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default React.memo(HoursOfOperation);
