import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './index.scss';
import { useEffect, useState } from 'react';

interface IProps {
  data: { text: string }[];
  setMessageChoose: (msg: string) => void;
}

function ListChoiceMessageChatGpt(props: IProps) {
  const { data, setMessageChoose } = props;
  const [indexChoose, setIndexChoose] = useState(data.length - 1 || 0);

  useEffect(() => {
    setIndexChoose(data.length - 1 || 0);
  }, [data]);

  return (
    <div className="ListChoiceMessageChatGpt">
      <div className="wrap-group-next">
        <LeftOutlined
          style={{
            color: indexChoose + 1 === 1 ? 'rgb(195 189 189)' : '#282828',
            cursor: indexChoose + 1 === 1 ? 'not-allowed' : 'pointer',
          }}
          onClick={() => {
            if (indexChoose === 0) return;
            const newIndex = indexChoose - 1;
            setIndexChoose(newIndex);
            const message = data[newIndex].text || '';
            if (message) {
              setMessageChoose(message);
            }
          }}
        />
        {indexChoose + 1}
        {' / '}
        {data.length}
        <RightOutlined
          style={{
            color: indexChoose + 1 === data.length ? 'rgb(195 189 189)' : '#282828',
            cursor: indexChoose + 1 === data.length ? 'not-allowed' : 'pointer',
          }}
          onClick={() => {
            if (indexChoose === data.length - 1) return;
            const newIndex = indexChoose + 1;
            setIndexChoose(newIndex);
            const message = data[newIndex].text || '';
            if (message) {
              setMessageChoose(message);
            }
          }}
        />
      </div>
    </div>
  );
}

export default ListChoiceMessageChatGpt;
