import { INCIDENT_ATTR_TYPES } from 'pages/setting/interfaces';

export interface IDetailIncidentsProps { }

export enum IIncidentDetailType {
  DETAIL = 'DETAIL',
  NOTES = 'NOTES',
  ACTIVITY_LOG = 'ACTIVITY LOG',
  CHAT = 'CHAT',
}

export enum EExportTypeIncident {
  PDF_INCIDENT = 'PDF_INCIDENT',
  EXCEL_INCIDENT = 'EXCEL_INCIDENT',
  PDF_ACTIVITY_LOG = 'PDF_ACTIVITY_LOG',
  EXCEL_ACTIVITY_LOG = 'EXCEL_ACTIVITY_LOG',
}

// loading incident detail

type ObjectDeatil = {
  _id: string;
  name: string;
  deleted: boolean;
  code?: number;
};

export interface IResponseIncidentDetail {
  sequence: number;
  active_user_id: any;
  actions_taken: any[];
  admin_id: string;
  assignees: any[];
  createdAt: string;
  description: string;
  happened_at: string | null;
  is_active: boolean;
  labels: any[];
  location: ObjectDeatil | null;
  org_id: ObjectDeatil | null;
  perpetrators: any[];
  priority: ObjectDeatil | null;
  questions: any[];
  status: ObjectDeatil | null;
  targets: any[];
  type: ObjectDeatil | null;
  files: any[];
  __v: number;
  _id: string;
  response_status: string | null;
  import_id: string | null;
  survey: any[];
  is_end_chat: boolean;
  anonymous_end_chat_status: string | null;
  anonymous_get_survey_at: Date | null;
  anonymous_send_survey_at: Date | null;
  is_survey_answered: Date | null;
  incident_survey: any | null;
}

export interface IPayloadGetDetail {
  query: {
    incident_id?: string | null;
  };
  onSuccess?: (data: any) => void;
  onError?: () => void;
}
export interface IActionGetDetail {
  type: string;
  payload: IPayloadGetDetail;
}

export interface IPayloadGetOrgAdmin {
  query: {
    organization_id?: string | null;
  };
  onSuccess?: (data: any) => void;
  onError?: () => void;
}

export interface IActionGetOrgAdmin {
  type: string;
  payload: IPayloadGetOrgAdmin;
}

export interface IUpdateDetailIncident {
  incident_id: string;
  status: string | null;
  type: string | null;
  happened_at: string | null;
  location: string | null;
  assignees: any[] | null;
  labels: any[] | null;
  perpetrators: any[] | null;
  targets: any[] | null;
  actions_taken: any[] | null;
  priority: string | null;
}

export interface IDeleteAttrsIncidentByOrg {
  incident_id: string;
  type: string | null;
  attrs: string[];
}

export interface IPayloadActionUpdateDetailIncident {
  data: IUpdateDetailIncident;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IPayloadActionDeleteAttrsIncidentByOrg {
  data: IDeleteAttrsIncidentByOrg;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IActionUpdateDetailIncident {
  type: string;
  payload: IPayloadActionUpdateDetailIncident;
}

export interface IActionDeleteAttrsIncidentByOrg {
  type: string;
  payload: IPayloadActionDeleteAttrsIncidentByOrg;
}

export interface IPayloadSearch {
  query: {
    organization_id?: string;
    type: INCIDENT_ATTR_TYPES;
    search: string;
  };
  onSuccess?: (data: any) => void;
  onError?: () => void;
}

export interface IActionSearch {
  type: string;
  payload: IPayloadSearch;
}

export interface IBoundsChat {
  left: number;
  top: number;
  bottom: number;
  right: number;
}

export interface IPayloadGetActivityLog {
  query: {
    incident_id?: string | null;
  };
  onSuccess?: (data: any) => void;
  onError?: () => void;
}

export interface IActionGetActivityLog {
  type: string;
  payload: IPayloadGetActivityLog;
}

export enum INCIDENT_LOG {
  CREATE_INCIDENT = 'create_incident',
  FIRST_VIEW_INCIDENT = 'first_view_incident',
  UPDATE_PRIORITY = 'update_priority',
  UPDATE_TYPE = 'update_type',
  UPDATE_STATUS = 'update_status',
  UPDATE_LOCATION = 'update_location',
  UPDATE_HAPPENED_AT = 'update_happened_at',
  REMOVE_ASSIGNEES = 'remove_assignees',
  ADD_ASSIGNEES = 'add_assignees',
  REMOVE_LABELS = 'remove_labels',
  ADD_LABELS = 'add_labels',
  REMOVE_TARGETS = 'remove_targets',
  ADD_TARGETS = 'add_targets',
  REMOVE_PERPETRATORS = 'remove_perpetrators',
  ADD_PERPETRATORS = 'add_perpetrators',
  REMOVE_ACTIONS_TAKEN = 'remove_actions_taken',
  ADD_ACTIONS_TAKEN = 'add_actions_taken',
  ADD_FILES = 'add_files',
}

export interface IQueryLoadingActivityLog {
  extra_data: any;
  new_value: any;
  old_value: string;
  target_id: {
    _id: string;
    sequence: number | null;
  };
  _id: string;
  user_id: {
    _id: string;
    first_name: string;
    last_name: string;
  };
  target: string;
  action: string;
  createdAt: string;
}

export interface IQueryLoadingActivityLogMultilanguage {
  category: string;
  new_value: any;
  old_value: any;
  admin_name: string;
  incident_id: string;
  client_id: string;
  time: any;
  view_action: string;
  file_id: string;
  mail_list: string;
  mail: string;
  description: string;
  download_type: string;
  deactive_action: string;
  platform: string;
}

// loading notes incidents
export interface IPayloadLoadingNotesIncidents {
  incident_id: string;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingNotesIncidents {
  type: string;
  payload: IPayloadLoadingNotesIncidents;
}

// create notes incidents
export interface ICreateNotesIncidentsFormValues {
  incident_id: string;
  content: string;
}
export interface IPayloadCreateNotesIncidents {
  values: ICreateNotesIncidentsFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionCreateNotesIncidents {
  type: string;
  payload: IPayloadCreateNotesIncidents;
}

// share incidents
export enum EShareFields {
  INCIDENT_STATUS = 'INCIDENT_STATUS',
  INCIDENT_TYPE = 'INCIDENT_TYPE',
  INCIDENT_LABELS = 'INCIDENT_LABELS',
  INCIDENT_QUESTION = 'INCIDENT_QUESTION',
  ASSIGNEE = 'ASSIGNEE',
  INCIDENT_LOCATION = 'INCIDENT_LOCATION',
  INCIDENT_PRIORITY = 'INCIDENT_PRIORITY',
  INCIDENT_TAKEN = 'INCIDENT_TAKEN',
  PERPETRATORS = 'PERPETRATORS',
  TARGETS = 'TARGETS',
  CHAT = 'CHAT',
  NOTES = 'NOTES',
  ATTACH_INCIDENT_DETAIL_PDF = 'ATTACH_INCIDENT_DETAIL_PDF',
}
export interface IShareIncidentFormValues {
  incident_id: string;
  to: string[];
  description: string;
  expired: string;
  share_fields: EShareFields[];
}

export interface IPayloadShareIncidents {
  values: IShareIncidentFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionShareIncidents {
  type: string;
  payload: IPayloadShareIncidents;
}

// Deactive user
export interface IPayLoadDeactiveUser {
  active_user_id?: string;
  incident_id?: string;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}

export interface IActionDeactiveUser {
  type: string;
  payload: IPayLoadDeactiveUser;
}

// loading chats
export interface IPayloadLoadingChatIncidents {
  incident_id: string;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingChatIncidents {
  type: string;
  payload: IPayloadLoadingChatIncidents;
}

export interface IPayloadAppendMessageIncidents {
  msg: object;
  onSuccess: () => void;
}
export interface IActionAppendMessageIncidents {
  type: string;
  payload: IPayloadAppendMessageIncidents;
}

// read chats
export interface IReadChatIncidentFormValues {
  incident_id: string;
}
export interface IPayloadReadChatIncidents {
  values: IReadChatIncidentFormValues;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionReadChatIncidents {
  type: string;
  payload: IPayloadReadChatIncidents;
}

export interface IActionSuggestAnswer {
  type: string;
  payload: IPayloadReadChatIncidents;
}

// other incident
export interface IPayloadLoadingOtherIncidents {
  device_user_id: string;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingOtherIncidents {
  type: string;
  payload: IPayloadLoadingOtherIncidents;
}

export interface IQueryPreviewImage {
  files: [];
}
export interface IQueryDownloadImage {
  files: any[];
  is_download: boolean;
  originalFilename?: string;
}

export interface IPayloadGetListAttrAll {
  query: {
    organization_id: string | null;
  };
  onSuccess?: (data: any) => void;
  onError?: () => void;
}

export interface IActionGetListAttrAll {
  type: string;
  payload: IPayloadGetListAttrAll;
}

export interface IDeleteChatIncidentFormValues {
  incident_id: string;
  chat_message_id: string;

}
export interface IPayloadDeleteChatIncidents {
  values: IDeleteChatIncidentFormValues;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionDeleteChatIncidents {
  type: string;
  payload: IPayloadDeleteChatIncidents;
}