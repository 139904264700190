import API from 'libs/axios';
import {
  ICreateIncidentsFormValues,
  IPayloadUrlUpload,
  IQueryLoadingIncidents,
  IPayloadGetDetail,
  IPayloadGetOrgAdmin,
  IPayloadSearch,
  IPayloadGetActivityLog,
  ICreateNotesIncidentsFormValues,
  IShareIncidentFormValues,
  IPayLoadDeactiveUser,
  IQueryLoadingTotalInfoIncidents,
  IReadChatIncidentFormValues,
  IQueryDownloadImage,
  IPayloadGetListAttrAll,
  IDeleteAttrsIncidentByOrg,
  IDeleteChatIncidentFormValues,
} from 'pages/incidents/interfaces';
import { queryString } from 'utils';

const PATH: string = 'admin/incident';

// upload files
export const postUrlUpload = (payload: IPayloadUrlUpload) =>
  API.post('amdmin/file/url-upload', payload);

export const postUrlUploadOther = (payload: IPayloadUrlUpload) =>
  API.post('amdmin/file/url-upload-other', payload);

// incidents
export const getIncident = (query: IQueryLoadingIncidents) => {
  const params: any = {};

  Object.entries(query).forEach(([key, value]: any) => {
    if (value) params[key] = value;
  });
  params['iv'] = Date.now();
  const qs: string = queryString(params);

  return API.get(`${PATH}/list?${qs}`);
};

export const postCreateIncidents = (payload: ICreateIncidentsFormValues) =>
  API.post(`${PATH}/create`, payload);

export const getIncidentDetail = (payload: IPayloadGetDetail['query']) =>
  API.get(`${PATH}/detail`, { params: payload });

export const getInfoOrgAdmin = (payload: IPayloadGetOrgAdmin['query']) => {
  return API.get(`admin/users/org_admin`, { params: payload });
};

export const apiPreview = (data) => {
  return API.post('amdmin/file/preview', data);
};

export const apiPreviewBroadcast = (data) => {
  return API.post('amdmin/file/preview-broadcast', data);
};
export const apiDownloadImage = (data: IQueryDownloadImage) => {
  return API.post('amdmin/file/preview', data);
};

export const getActiviLog = (payload: IPayloadGetActivityLog['query']) => {
  return API.get(`admin/activity_log/incident`, { params: payload });
};

export const updateFormDetailIncident = (data) => {
  return API.post(`${PATH}/update`, data);
};

export const apiSearch = (query: IPayloadSearch['query']) =>
  API.get(`admin/incident/attr/list`, { params: query });

export const getNotes = (incident_id: string) =>
  API.get(`admin/note-incident/detail?incident_id=${incident_id}`);

export const postCreateNotes = (payload: ICreateNotesIncidentsFormValues) =>
  API.post(`admin/note-incident/create`, payload);

export const postShareIncidents = (payload: IShareIncidentFormValues) =>
  API.post(`admin/share-incident/create`, payload);

export const postDeactiveUser = (payload: IPayLoadDeactiveUser) =>
  API.post(`admin/incident/block-user`, payload);

export const deleteAttrsIncidentByOrg = (data: IDeleteAttrsIncidentByOrg) =>
  API.post(`admin/incident/delete-attrs-incident-org`, data);

export const getTotalInfoIncidents = (query: IQueryLoadingTotalInfoIncidents) =>
  API.get(
    `${PATH}/init_info?organization_id=${query.organization_id}&show_hidden=${query.show_hidden}`
  );

export const getOtherIncidents = (
  device_user_id: string,
  selectedOrgId: string,
  show_hidden: boolean
) =>
  API.get(
    `${PATH}/other_incidents?device_user_id=${device_user_id}&org_id=${selectedOrgId}&show_hidden=${show_hidden}`
  );

export const getListIncidentAttrAll = (payload: IPayloadGetListAttrAll['query']) => {
  return API.get(`/admin/incident/attr/all`, { params: payload });
};

// Chats
export const getChatIncidents = (incident_id: string) =>
  API.get(`incident/chat/admin/messages?incident_id=${incident_id}`);

export const postReadChatIncidents = (payload: IReadChatIncidentFormValues) =>
  API.post('incident/chat/admin/read-message', payload);

export const getSuggestAnswer = (incident_id: string) =>
  API.get(`incident/chat/admin/suggestion?incident_id=${incident_id}`);

export const updateIncidentFilter = (data) => {
  return API.post(`admin/users/incident-filter`, data);
};
export const postDeleteChatIncidents = (payload: IDeleteChatIncidentFormValues) =>
  API.post('incident/chat/admin/delete-message', payload);

export const apiGetCreateTestIncident = (payload) =>
  API.get(`admin/incident/create-test-incident`, { params: payload });
